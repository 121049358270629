import { type GeoLocation } from '@vakantiesnl/types/src/geo';

import { type ActionTypes } from './actions';
import { SET_QUICKSEARCH_COUNTRY, SET_QUICKSEARCH_DEPARTURE_DATE } from './constants';

export type State = {
	countries: GeoLocation[] | null;
	departureDate: Date | null;
};

export const initialState: State = {
	countries: null,
	departureDate: null,
};

export const reducer = (state: State, data: ActionTypes): State => {
	switch (data.type) {
		case SET_QUICKSEARCH_COUNTRY: {
			return {
				...state,
				countries: data.payload,
			};
		}

		case SET_QUICKSEARCH_DEPARTURE_DATE: {
			return {
				...state,
				departureDate: data.payload,
			};
		}

		default: {
			return state;
		}
	}
};
