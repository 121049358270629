import { type FC, memo } from 'react';

import { type MicroCopy } from '@vakantiesnl/types';

import styles from './Rating.module.css';

type Props = {
	microCopies: MicroCopy;
	ratingCount: number;
	ratingScore: number;
};

/**
 * Gets the style class for the rating based on the rating score.
 *
 * @param ratingScore
 */
export const getVariantStyle = (ratingScore: number): string => {
	if (ratingScore < 7) {
		return 'normal';
	} else if (ratingScore >= 7 && ratingScore <= 9) {
		return 'good';
	} else {
		return 'excellent';
	}
};

/**
 * Gets the grade text based on the rating score.
 */
export const getGradeText = (ratingScore: number, microCopies: MicroCopy): string => {
	if (ratingScore >= 6 && ratingScore <= 6.9) {
		return microCopies['ratingNormal'];
	} else if (ratingScore >= 7 && ratingScore <= 7.9) {
		return microCopies['ratingGood'];
	} else if (ratingScore >= 8 && ratingScore <= 8.4) {
		return microCopies['ratingHigh'];
	} else if (ratingScore >= 8.5 && ratingScore <= 8.9) {
		return microCopies['ratingVeryHigh'];
	} else if (ratingScore >= 9 && ratingScore <= 9.5) {
		return microCopies['ratingUltraHigh'];
	} else if (ratingScore >= 9.6 && ratingScore <= 9.9) {
		return microCopies['ratingTremendous'];
	} else if (ratingScore >= 10) {
		return microCopies['ratingPerfect'];
	} else {
		return '';
	}
};

export const replaceDotWithComma = (ratingScore: number): string => {
	ratingScore = ratingScore % 1 === 0 ? parseInt(ratingScore.toString()) : ratingScore;
	return ratingScore ? ratingScore.toString().replace('.', ',') : '';
};

const Rating: FC<Props> = ({ ratingCount, ratingScore, microCopies }) => (
	<div className={styles.rating}>
		<div className={styles.ratingContent}>
			<p className={styles.ratingGrade + ' ' + styles[getVariantStyle(ratingScore)]}>
				{getGradeText(ratingScore, microCopies)}
			</p>
			<p className={styles.ratingCount}>{ratingCount} beoordelingen</p>
		</div>
		<div className={styles.ratingScore + ' ' + styles[getVariantStyle(ratingScore)]}>
			<p className={styles.ratingScoreText}>{replaceDotWithComma(ratingScore)}</p>
		</div>
	</div>
);

export default memo(Rating);
