import { type Dispatch, useCallback, useMemo, useReducer } from 'react';

import * as actions from './actions';
import { reducer, initialState, type State } from './reducer';

export type DispatchActions<DataType> = {
	dispatch: Dispatch<actions.ActionTypes<DataType>>;
	receiveError: (payload: Error) => void;
	receiveResponse: (payload: DataType) => void;
	resetState: () => void;
	setData: (payload: DataType) => void;
	setError: (payload: Error) => void;
	setLoading: (payload: boolean) => void;
};

/**
 * Exposes the current state of the REST response
 * as well as an API for updating that state.
 */
export const useRestResponse = <DataType>(): [State<DataType>, DispatchActions<DataType>] => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const setData: DispatchActions<DataType>['setData'] = useCallback(
		(payload) => dispatch(actions.setState(payload)),
		[dispatch],
	);
	const setError: DispatchActions<DataType>['setError'] = useCallback(
		(payload) => dispatch(actions.setError(payload)),
		[dispatch],
	);
	const setLoading: DispatchActions<DataType>['setLoading'] = useCallback(
		(payload) => dispatch(actions.setLoading(payload)),
		[dispatch],
	);
	const receiveError: DispatchActions<DataType>['receiveError'] = useCallback(
		(payload) => dispatch(actions.setReceiveError(payload)),
		[dispatch],
	);
	const receiveResponse: DispatchActions<DataType>['receiveResponse'] = useCallback(
		(payload) => dispatch(actions.setReceiveResponse(payload)),
		[dispatch],
	);
	const resetState: DispatchActions<DataType>['resetState'] = useCallback(
		() => dispatch(actions.setResetState()),
		[dispatch],
	);

	const api: DispatchActions<DataType> = useMemo(
		() => ({
			dispatch,
			receiveError,
			receiveResponse,
			resetState,
			setData,
			setError,
			setLoading,
		}),
		[dispatch, receiveError, receiveResponse, resetState, setData, setError, setLoading],
	);

	return [state as State<DataType>, api];
};
