import { type FC, type ReactNode } from 'react';

import classNames from 'classnames';

import css from './Overlay.module.css';

type Props = { children: ReactNode; className?: string };

const Overlay: FC<Props> = ({ children, className }) => (
	<div className={classNames(css.overlayContainer, className)}>{children}</div>
);

export { Overlay };
