import type { FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { type NextApiRequest, type NextApiResponse } from 'next/types';
import { UnleashClient, type IMutableContext, InMemoryStorageProvider } from 'unleash-proxy-client';

export const getAllFeatureToggles = async (
	context: IMutableContext,
	mswFeatureMock?: string,
): Promise<FeatureToggle> => {
	const client = await getOrInitializeUnleashClient(mswFeatureMock);
	await client.updateContext(context);

	return client.getAllToggles().reduce<FeatureToggle>(
		(features, toggle) =>
			Object.assign(features, {
				[toggle.name]: {
					isEnabled: client.isEnabled(toggle.name),
					variant: client.getVariant(toggle.name),
				},
			}),
		{},
	);
};

let unleashClient: UnleashClient;

export const getOrInitializeUnleashClient = async (mswFeatureMock?: string): Promise<UnleashClient> => {
	// Disabled when testing otherwise we always get the same set of feature toggles back
	if (unleashClient && process.env.NEXT_PUBLIC_API_MOCKING !== 'true') {
		return unleashClient;
	}

	const queryParams = mswFeatureMock ? `?mswFeatureMock=${mswFeatureMock}` : '';

	unleashClient = new UnleashClient({
		url: `${process.env.NEXT_PUBLIC_UNLEASH_URL}/proxy${queryParams}`,
		appName: process.env.NEXT_PUBLIC_BRAND as string,
		clientKey: process.env.NEXT_PUBLIC_UNLEASH_CLIENT_KEY,
		storageProvider: new InMemoryStorageProvider(),
		refreshInterval: 300,
	});

	await unleashClient.start();

	return unleashClient;
};

export const featuresAPI = async (
	req: NextApiRequest,
	res: NextApiResponse<FeatureToggle | { error: unknown }>,
): Promise<void> => {
	// Headers are lowercase, so we use `sessionid` here and don't use `sessionId`.
	const sessionId = req.headers['sessionid'] as string;
	try {
		const features = await getAllFeatureToggles({ sessionId });
		res.setHeader('Cache-control', 'private, max-age=300').status(200).json(features);
	} catch (error) {
		res.status(500).json({
			error: error,
		});
	}
	res.end();
};
