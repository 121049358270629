import { useCallback, useRef, useState, useMemo, useEffect } from 'react';

import { ClickAwayListener, Popper } from '@mui/base';
import { useSelect, type UseSelectListboxSlotProps } from '@mui/base/useSelect';
import { Drawer } from '@vakantiesnl/components/src/atoms/Drawer';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon/Icon';
import { InputAdornmentComponent } from '@vakantiesnl/components/src/atoms/InputAdornment';
import { MultiRoomPartyPicker } from '@vakantiesnl/components/src/molecules/MultiRoomPartyPicker';
import { TextFieldComponent, type TextfieldComponentProps } from '@vakantiesnl/components/src/molecules/TextField';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { buildSummarizedPartyString } from '@vakantiesnl/services/src/util/partyUtils';
import { type Search } from '@vakantiesnl/types/src';

import { type SharedSelectProps, popperModifiers } from '../SelectFormField';
import { useCustomStyles } from '../SelectFormField.style';

export type SelectFormFieldMultiRoomProps = Omit<SharedSelectProps, 'onChange' | 'InputProps'> &
	Pick<Partial<SharedSelectProps>, 'InputProps'> & {
		initialParty?: Search.PartyComposition[];
		listboxProps?: UseSelectListboxSlotProps;
		listboxVisible?: boolean;
		onChange?: (pax: Search.PartyComposition[]) => void;
		onClose?: (pax: Search.PartyComposition[]) => void;
		onOpen?: () => void;
		/** Toggle to hide multi room selection, purpose for single room selection for Qenner */
		hideMultiRoom?: boolean;
	};

export function SelectFormFieldMultiRoom({
	helperText,
	label,
	inputClassName,
	listBoxClassName,
	placeholder,
	InputProps,
	onChange,
	onClose,
	onOpen,
	initialParty,
	hideMultiRoom,
	...props
}: SelectFormFieldMultiRoomProps): JSX.Element {
	const { isDesktop } = useBreakpoints();
	const microCopies = useMicroCopyContext();
	const { classes, cx } = useCustomStyles();
	const [value, setValue] = useState(props.defaultValue);
	const listboxRef = useRef(null);
	const buttonRef = useRef(null);
	const [dropdownVisible, SetDropdownVisible] = useState<boolean>(false);

	const { getListboxProps, getButtonProps } = useSelect({
		...props,
		listboxRef,
		buttonRef,
		value,
	});

	const setPaxStringValue = useCallback(
		(rooms: Search.PartyComposition[], updateValue?: boolean) => {
			onChange && onChange(rooms);
			if (isDesktop || updateValue) {
				setValue(buildSummarizedPartyString(rooms, microCopies));
			}
		},
		[microCopies, onChange, isDesktop],
	);

	const extendedInputProps: TextfieldComponentProps['InputProps'] = useMemo(
		() => ({
			...InputProps,
			startAdornment: (
				<InputAdornmentComponent position="start" type="icon">
					<Icon type="familyRoom" />
				</InputAdornmentComponent>
			),
			endAdornment: (
				<InputAdornmentComponent position="end" type="icon">
					<Icon type="chevronDown" />
				</InputAdornmentComponent>
			),
			readOnly: true,
			className: classes.inputComponent,
			...getButtonProps(),
		}),
		[InputProps, classes.inputComponent, getButtonProps],
	);

	const handleToggleClick = useCallback(() => {
		SetDropdownVisible((prevState) => !prevState);
	}, []);

	const handleOnClose = useCallback(
		(party: Search.PartyComposition[]) => {
			if (!isDesktop) {
				SetDropdownVisible(false);
			}
			onClose?.(party);
		},
		[onClose, isDesktop],
	);

	const handleClickAway = useCallback(() => {
		if (dropdownVisible) {
			SetDropdownVisible(false);
		}
	}, [dropdownVisible]);

	useEffect(() => {
		if (dropdownVisible && onOpen) {
			onOpen();
		}
	}, [dropdownVisible, onOpen]);

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div data-cy="sortingDropdownPax">
				<TextFieldComponent
					className={inputClassName}
					helperText={!dropdownVisible && helperText}
					label={label}
					placeholder={placeholder}
					value={value}
					InputProps={extendedInputProps}
					onClick={handleToggleClick}
					data-cy="paxDropdown"
				/>
				{isDesktop ? (
					<Popper
						open={dropdownVisible}
						anchorEl={buttonRef?.current ?? null}
						modifiers={popperModifiers}
						placement="bottom-start"
						role="listbox"
						className={classes.popper}
						disablePortal
						keepMounted
					>
						<div
							{...getListboxProps()}
							aria-hidden={!dropdownVisible}
							className={cx(classes.listBox, listBoxClassName, classes.paxListBox)}
						>
							<MultiRoomPartyPicker
								microCopy={microCopies}
								initialParty={initialParty}
								onPartyUpdate={setPaxStringValue}
								onClose={handleOnClose}
								isOpen={dropdownVisible}
								customWrapperStyles={classes.multiRoomPartyPickerWrapper}
								hideMultiRoom={hideMultiRoom}
							/>
						</div>
					</Popper>
				) : (
					<Drawer
						open={dropdownVisible}
						isFullHeight={true}
						hasBackButton={true}
						title={microCopies['common.travel_party']}
						onDrawerClose={handleClickAway}
						onClose={handleClickAway}
					>
						<MultiRoomPartyPicker
							initialParty={initialParty}
							microCopy={microCopies}
							onPartyUpdate={setPaxStringValue}
							onClose={handleOnClose}
							hideMultiRoom={hideMultiRoom}
							isOpen
						/>
					</Drawer>
				)}
			</div>
		</ClickAwayListener>
	);
}
