import { type NextRouter } from 'next/router';

export const getDomainFromDomainLocales = (
	locale: NextRouter['locale'],
	domainLocales: NextRouter['domainLocales'],
	fallBackDomain: string,
): string => {
	if (!locale || !domainLocales?.length) return fallBackDomain;

	const matchedDomainLocale = domainLocales.find((domainLocale) => domainLocale.defaultLocale === locale);

	if (matchedDomainLocale)
		return !/^https?:\/\//i.test(matchedDomainLocale.domain)
			? `https://${matchedDomainLocale.domain}`
			: matchedDomainLocale.domain;

	return fallBackDomain;
};
