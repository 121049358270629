import { useContext, createContext, useMemo, type FC } from 'react';

import { usePopular } from '../../hooks/rest/recommendations';

type Props = {
	children: React.ReactNode;
	limit?: string;
};

export type UseRecommendationsResponse = {
	giata_ids: string[] | null;
	page_size: string;
};

type SearchStructureState = {
	loading: boolean;
	error: Error | null;
	data: UseRecommendationsResponse;
};

type ContextProps = {
	state: SearchStructureState;
};

const RecommendationsContext = createContext<ContextProps>({} as ContextProps);

export const RecommendationsProvider: FC<Props> = ({ children, limit = '3' }) => {
	const { data, loading, error } = usePopular(limit);

	const giata_ids = data !== null ? data.giataids : null;

	const value = useMemo(
		() => ({
			state: {
				loading,
				error,
				data: {
					giata_ids: giata_ids,
					page_size: limit,
				},
			},
		}),
		[loading, error, giata_ids, limit],
	);

	return <RecommendationsContext.Provider value={value}>{children}</RecommendationsContext.Provider>;
};

export const useRecommendationsContext = (): ContextProps => useContext(RecommendationsContext);
