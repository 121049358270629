import { type FC, useCallback, useMemo } from 'react';

import { Divider } from '@vakantiesnl/components/src/atoms/Divider';
import { NumberPicker } from '@vakantiesnl/components/src/atoms/NumberPicker/NumberPicker';
import { getPartyMaximums } from '@vakantiesnl/services/src/util/partyUtils';
import { type MicroCopy, type Search } from '@vakantiesnl/types';
import { type PartyCategory } from '@vakantiesnl/types/src/search';

import { useCustomStyles } from './SingleRoomPartyPicker.style';

type Props = {
	roomIndex: number;
	party: Search.PartyComposition;
	maxCount: number;
	microCopy: MicroCopy;
	onRoomPartyUpdate: (index: number, party: Search.PartyComposition) => void;
};

const defaultMaximums: Search.PartyComposition = { adults: 9, children: 8, babies: 8 };

export const SingleRoomPartyPicker: FC<Props> = ({ roomIndex, party, maxCount, microCopy, onRoomPartyUpdate }) => {
	const { classes } = useCustomStyles();

	const maximums = useMemo(() => getPartyMaximums(party, maxCount, defaultMaximums), [maxCount, party]);

	const updateParty = useCallback(
		(category: PartyCategory) => (count: number) => onRoomPartyUpdate(roomIndex, { ...party, [category]: count }),
		[party, onRoomPartyUpdate, roomIndex],
	);

	return (
		<div>
			<div data-cy="single-room-party-picker">
				<NumberPicker
					key={`adults-${party.adults}`}
					title={microCopy['filters.adultsPlural']}
					description={microCopy['filters.adultsAgeGroup']}
					currentCount={party.adults}
					minCount={1}
					maxCount={maximums.adults}
					microCopy={microCopy}
					className={classes.numberPicker}
					onChange={updateParty('adults')}
					showError={false}
				/>
				<Divider className={classes.divider} />
				<NumberPicker
					key={`children-${party.children}`}
					title={microCopy['filters.childrenPlural']}
					description={microCopy['filters.childrenAgeGroup']}
					currentCount={party.children}
					maxCount={maximums.children}
					microCopy={microCopy}
					className={classes.numberPicker}
					onChange={updateParty('children')}
					showError={false}
				/>
				<Divider className={classes.divider} />
				<NumberPicker
					key={`babies-${party.babies}`}
					title={microCopy['filters.babiesPlural']}
					description={microCopy['filters.babiesAgeGroup']}
					currentCount={party.babies}
					maxCount={maximums.babies}
					microCopy={microCopy}
					className={classes.numberPicker}
					onChange={updateParty('babies')}
					showError={false}
				/>
			</div>
		</div>
	);
};
