import { type FC } from 'react';

import { Overlay } from '@vakantiesnl/components/src/__LEGACY__/atoms/Overlay';
import { Rating } from '@vakantiesnl/components/src/__LEGACY__/atoms/Rating';
import ImageComponent from '@vakantiesnl/image-component';
import { type MicroCopy } from '@vakantiesnl/types';
import classNames from 'classnames';

import styles from './ImageWithRating.module.css';

type Props = {
	microCopies: MicroCopy;
	reviewCount?: number;
	reviewScore?: number;
	imageSrc: string;
	alt: string;
	imageClassName?: string;
	rotationDegrees?: number;
	priority?: boolean;
	sizes?: string;
};

export const ImageWithRating: FC<Props> = ({
	microCopies,
	reviewCount,
	reviewScore,
	imageSrc,
	imageClassName,
	alt,
	rotationDegrees,
	priority = false,
	sizes,
}) => (
	<Overlay className={classNames(styles.overlayPosition, !(reviewCount && reviewScore) && styles.noFlare)}>
		<div className={styles.imageContainer}>
			<ImageComponent
				fill={true}
				alt={alt}
				src={imageSrc}
				rotationDegrees={rotationDegrees}
				className={classNames(styles.image, imageClassName)}
				priority={priority}
				sizes={sizes}
			/>
			{reviewCount && reviewScore && microCopies && (
				<div className={styles.rating}>
					<Rating ratingCount={reviewCount} ratingScore={reviewScore} microCopies={microCopies} />
				</div>
			)}
		</div>
	</Overlay>
);
