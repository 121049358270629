import { useMemo } from 'react';

import { type State } from '@vakantiesnl/services/src/hooks/rest/rest-response';

import { useRestApi } from '../useRestApi';

export interface RecommendationsStructureState {
	giataids: string[];
}

const usePopularRequest = (limit: string): RequestInfo =>
	useMemo(
		() =>
			new Request(
				`${process.env.NEXT_PUBLIC_USER_ENDPOINT_URL}/api/v1/recommendation/${
					process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? 'zoover' : 'vaknl'
				}/accommodation/popular?limit=${limit}`,
				{
					method: 'GET',
				},
			),
		[limit],
	);
const useSimilarUsersRequest = (id: string): RequestInfo =>
	useMemo(
		() =>
			new Request(
				`${process.env.NEXT_PUBLIC_USER_ENDPOINT_URL}/api/v1/dmp_user/${id}/recommendation/popular_among_similar_users`,
				{
					method: 'GET',
				},
			),
		[id],
	);
const useUserRecommendationsRequest = (id: string): RequestInfo =>
	useMemo(
		() =>
			new Request(`${process.env.NEXT_PUBLIC_USER_ENDPOINT_URL}/api/v1/dmp_user/${id}/recommendation`, {
				method: 'GET',
			}),
		[id],
	);

export const usePopular = (limit: string): State<RecommendationsStructureState> => {
	const request = usePopularRequest(limit);
	const [restState] = useRestApi<RecommendationsStructureState>(request);
	return restState;
};

export const useUserRecommendations = (id: string): State<RecommendationsStructureState> => {
	const request = useUserRecommendationsRequest(id);
	const [restState] = useRestApi<RecommendationsStructureState>(request);
	return restState;
};

export const useSimilarUsers = (id: string): State<RecommendationsStructureState> => {
	const request = useSimilarUsersRequest(id);
	const [restState] = useRestApi<RecommendationsStructureState>(request);
	return restState;
};
