import { type ReactElement, forwardRef, useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { ClickAwayListener } from '@mui/base';
import { Option, type OptionRootSlotProps } from '@mui/base/Option';
import { Popper, type PopperOwnProps } from '@mui/base/Popper';
import { type SelectProps } from '@mui/base/Select';
import { useSelect, SelectProvider } from '@mui/base/useSelect';
import {
	type SelectChangeEventType,
	type SelectOptionDefinition,
	type SelectValue,
} from '@mui/base/useSelect/useSelect.types';
import { type State } from '@popperjs/core';
import { Checkbox } from '@vakantiesnl/components/src/atoms/Checkbox';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { InputAdornmentComponent } from '@vakantiesnl/components/src/atoms/InputAdornment';
import { Radio } from '@vakantiesnl/components/src/atoms/Radio';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { TextFieldComponent, type TextfieldComponentProps } from '@vakantiesnl/components/src/molecules/TextField';
import { type MicroCopy } from '@vakantiesnl/types';

import { useCustomStyles } from './OldSelectFormField.style';

export type SharedSelectProps = Omit<TextfieldComponentProps, 'variant' | 'onChange'> & {
	helperText?: string;
	label: string;
	inputClassName?: string;
	listBoxClassName?: string;
	disabled?: boolean;
	onOpen?: () => void;
};

export const popperModifiers: PopperOwnProps['modifiers'] = [
	{
		name: 'sameWidth',
		enabled: true,
		fn: ({ state }: { state: State }): void => {
			state.styles.popper.width = `${state.rects.reference.width}px`;
		},
		phase: 'beforeWrite',
		requires: ['computeStyles'],
	},
	{ name: 'flip', enabled: false },
];

export type SelectFormFieldRenderValue<Multiple> = (
	value: SelectValue<string, Multiple>,
	options: SelectOptionDefinition<string>[],
) => string | undefined;
export type SelectFormFieldProps<OptionValue extends string | string[], Multiple extends boolean> = SharedSelectProps &
	SelectProps<string, Multiple> & {
		microCopies: MicroCopy;
		options: SelectOptionDefinition<OptionValue>[];
		sectionTitle?: string;
		variant?: 'checkbox' | 'default' | 'radio';
		multiple?: Multiple;
		/** Specify the type so it could be re-used */
		onChange?: (_e: SelectChangeEventType, selectedValue: SelectValue<string, Multiple>) => void;
		customRenderValue?: SelectFormFieldRenderValue<Multiple>;
		dataCy?: string;
	};

export function SelectFormField<OptionValue extends string, Multiple extends boolean = false>({
	microCopies,
	helperText,
	label,
	options,
	sectionTitle,
	inputClassName,
	listBoxClassName,
	variant = 'default',
	placeholder,
	customRenderValue,
	disabled,
	error,
	InputProps,
	onChange,
	multiple,
	dataCy,
	onOpen,
	...props
}: SelectFormFieldProps<OptionValue, Multiple>): JSX.Element {
	const { classes, cx } = useCustomStyles();
	const listboxRef = useRef<HTMLUListElement>(null);
	const buttonRef = useRef<HTMLUListElement>(null);
	const [listboxVisible, setListboxVisible] = useState<boolean>(false);

	const items = useMemo(
		() => ({
			// eslint-disable-next-line react/display-name
			root: forwardRef<HTMLLIElement, OptionRootSlotProps<string>>(
				({ ownerState, className, children, ...props }, ref): ReactElement => (
					<li
						className={cx(classes.listItem, variant === 'default' && classes.defaultList, className)}
						{...props}
						ref={ref}
					>
						{variant === 'radio' && <Radio readOnly checked={ownerState.selected} />}
						{variant === 'checkbox' && <Checkbox readOnly checked={ownerState.selected} />}
						{variant === 'default' && ownerState.selected && <Icon type="checkmark" />}
						<Typography variant="bodyMd" as="p" className={classes.optionLabel}>
							{children}
						</Typography>
					</li>
				),
			),
		}),
		[classes.defaultList, classes.listItem, classes.optionLabel, cx, variant],
	);

	const handleClose = useCallback(() => {
		if (listboxVisible) {
			setListboxVisible(false);
		}
	}, [listboxVisible]);

	const onChangeHandler = useCallback(
		(e: SelectChangeEventType, selectedValue: SelectValue<string, Multiple>) => {
			/** Upon selecting an option, close the dropdown if it's not multiple select */
			if (!multiple) handleClose();
			onChange?.(e, selectedValue);
		},
		[handleClose, multiple, onChange],
	);

	const { contextValue, getListboxProps, getButtonProps, value, open } = useSelect<string, Multiple>({
		onChange: onChangeHandler,
		multiple,
		...props,
		listboxRef,
		buttonRef,
		open: listboxVisible,
	});

	const renderSelectedValue = useCallback(
		(value: string | string[] | null, options: SelectOptionDefinition<string>[]): string | undefined => {
			let selectedOption;
			if (Array.isArray(value)) {
				const selectedCount = value.length;
				if (selectedCount > 1) {
					return `${selectedCount} ${microCopies['common.optionsChosen']}`;
				}
				const selectedItems = options.filter((item) => {
					return value.includes(item.value);
				});

				const selectedValues: string[] = [];
				selectedItems.forEach((item) => {
					selectedValues.push(item.label);
				});

				return selectedValues.join(', ');
			} else {
				selectedOption = options.find((option) => option.value === value)?.label;
			}

			return selectedOption;
		},
		[microCopies],
	);

	const extendedInputProps: TextfieldComponentProps['InputProps'] = useMemo(
		() => ({
			...InputProps,
			endAdornment: (
				<InputAdornmentComponent position="end" type="icon">
					<Icon type="chevronDown" />
				</InputAdornmentComponent>
			),
			readOnly: true,
			className: classes.inputComponent,
			...getButtonProps(),
		}),
		[InputProps, classes.inputComponent, getButtonProps],
	);

	const handleToggleClick = useCallback(() => {
		setListboxVisible((prevState) => !prevState);
	}, []);

	useEffect(() => {
		if (open && onOpen) {
			onOpen();
		}
	}, [open, onOpen]);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div data-cy={dataCy ?? 'sortingDropdown'}>
				<TextFieldComponent
					className={inputClassName}
					helperText={!open && helperText}
					label={label}
					placeholder={placeholder}
					value={
						customRenderValue
							? customRenderValue(value, options)
							: value && renderSelectedValue(value, options)
					}
					InputProps={extendedInputProps}
					disabled={disabled}
					error={error}
					onClick={handleToggleClick}
				/>
				<Popper
					open={open}
					anchorEl={buttonRef?.current ?? null}
					modifiers={popperModifiers}
					placement="bottom-start"
					role="listbox"
					className={classes.popper}
					disablePortal
					keepMounted
				>
					<ul {...getListboxProps()} aria-hidden={!open} className={cx(classes.listBox, listBoxClassName)}>
						{sectionTitle && <span className={classes.sectionTitle}>{sectionTitle}</span>}
						<SelectProvider value={contextValue}>
							{options.map((option, index: number) => (
								<Option
									key={index}
									value={option.value}
									disabled={option.disabled}
									slots={items}
									label={option.label}
									data-cy={`sortingOption_${option.value}`}
								>
									{option.label}
								</Option>
							))}
						</SelectProvider>
					</ul>
				</Popper>
			</div>
		</ClickAwayListener>
	);
}
