import { type FC, memo } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import classNames from 'classnames';

import css from './StarRating.module.css';

type Props = {
	starCount: number;
	className?: string;
};

const getStarItems = (numberOfStars: number): number[] => Array.from(Array(numberOfStars).keys());

const StarRating: FC<Props> = ({ starCount, className }) => (
	<ul className={classNames(css.starRating, className)}>
		{getStarItems(starCount).map((key) => (
			<li key={key}>
				<Icon className={css.star} type="vaknlStar" />
			</li>
		))}
	</ul>
);

export default memo(StarRating);
