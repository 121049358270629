import { type FC, useCallback, useEffect, useState } from 'react';

import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { addUpCount, subtractCount } from '@vakantiesnl/services/src/util/mathUtils';
import { inject } from '@vakantiesnl/services/src/util/template';
import { type MicroCopy } from '@vakantiesnl/types';

import { useCustomStyles } from './NumberPicker.style';

export type NumberPickerProps = {
	title: string;
	description: string;
	currentCount?: number;
	minCount?: number;
	maxCount: number;
	microCopy: MicroCopy;
	className?: string;
	onChange?: (count: number) => void;
	showError?: boolean;
};

export const NumberPicker: FC<NumberPickerProps> = ({
	title,
	description,
	currentCount,
	minCount = 0,
	maxCount,
	microCopy,
	className,
	onChange,
	showError = true,
}) => {
	const [count, setCount] = useState<number>(currentCount ? currentCount : 0);
	const { classes } = useCustomStyles({ count });

	const subtract = useCallback(() => {
		setCount((currentCount) => {
			const newCount = subtractCount(currentCount, minCount);
			if (onChange) {
				onChange(newCount);
			}
			return newCount;
		});
	}, [minCount, onChange]);

	const addUp = useCallback(() => {
		setCount((currentCount) => {
			const newCount = addUpCount(currentCount, maxCount);

			if (onChange) {
				onChange(newCount);
			}
			return newCount;
		});
	}, [maxCount, onChange]);

	useEffect((): void => {
		if (currentCount) {
			setCount(currentCount);
		}
	}, [currentCount]);

	return (
		<div className={className} data-cy="numberpicker">
			<div className={classes.numberPickerContainer}>
				<div className={classes.text}>
					<Typography variant="labelMd" as="span" className={classes.title}>
						{title}
					</Typography>
					<Typography variant="bodySm" as="span" className={classes.description}>
						{description}
					</Typography>
				</div>
				<div className={classes.numberPicker}>
					<Button
						variant="tertiary"
						size="small"
						onClick={subtract}
						disabled={count === minCount}
						leadingIcon="minus"
					/>
					<Typography variant="labelMd" as="span" className={classes.count}>
						{count}
					</Typography>
					<Button
						variant="tertiary"
						size="small"
						onClick={addUp}
						disabled={count === maxCount}
						leadingIcon="plus"
					/>
				</div>
			</div>
			<div className={classes.numberPickerInfo}>
				{count === maxCount && showError && (
					<span className={classes.warning}>
						{inject(microCopy['common.numberpicker.maxInfo'], { maxCount: maxCount.toString() })}
					</span>
				)}
			</div>
		</div>
	);
};
