import { type ForwardedRef, forwardRef, type InputHTMLAttributes } from 'react';

import { useCustomStyles } from './Radio.style';

type BaseInputProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'required' | 'checked' | 'disabled' | 'value' | 'name' | 'readOnly' | 'onChange' | 'id' | 'tabIndex'
> & { ref?: ForwardedRef<HTMLInputElement> };

export type RadioProps = BaseInputProps & {
	hasNoFocusState?: boolean;
};

// Type hack due to mismatch with @mui/material and @types/react https://github.com/mui/material-ui/issues/41906
export const Radio: React.ForwardRefExoticComponent<Omit<RadioProps, 'ref'> & React.RefAttributes<HTMLInputElement>> =
	forwardRef<HTMLInputElement, RadioProps>(({ hasNoFocusState, ...inputProps }, ref) => {
		const { classes, cx } = useCustomStyles();

		return (
			<div className={classes.container}>
				<input
					className={cx(classes.radioButton, { [classes.hasNoFocusState]: hasNoFocusState })}
					type="radio"
					{...inputProps}
					ref={ref}
				/>
				<span className={classes.radioSpan} />
			</div>
		);
	});
Radio.displayName = 'Radio';
