import { type FC, type ReactNode } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import ImageComponent from '@vakantiesnl/image-component';
import { type ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';

import styles from './QuickSearchHeader.module.css';

type Props = {
	backgroundImgSrc: Optional<string>;
	backgroundImgName: Optional<string>;
	title: string;
	quickSearchTitle?: string;
	children: ReactNode;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp', fit: 'fill', f: 'center' };

export const QuickSearchHeader: FC<Props> = ({
	backgroundImgSrc,
	backgroundImgName,
	title,
	quickSearchTitle,
	children,
}) => (
	<Content variant="full-width">
		<div className={styles.quickSearchHeader}>
			{backgroundImgSrc && backgroundImgName && (
				<ImageComponent
					useLoader="Contentful"
					contentfulParams={contentfulParams}
					fill={true}
					alt={backgroundImgName}
					className={styles.headerImage}
					src={backgroundImgSrc}
				/>
			)}
			<div className={styles.container}>
				<div className={styles.content}>
					<span className={styles.title}>{title}</span>
					<Content className={styles.quickSearch}>
						{quickSearchTitle && <span className={styles.quickSearchTitle}>{quickSearchTitle}</span>}
						<div className={styles.containerSearchInput}>{children}</div>
					</Content>
				</div>
			</div>
		</div>
	</Content>
);
