import { type FC, memo } from 'react';

import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { UspExpanded } from '@vakantiesnl/components/src/__LEGACY__/atoms/UspExpanded';
import { type Usp } from '@vakantiesnl/types';

import css from './UspExpandedCollection.module.css';

export type UspExpandedCollectionProps = {
	title: string;
	usps: Usp[];
};

const UspExpandedCollection: FC<UspExpandedCollectionProps> = ({ title, usps }) => (
	<div className={css.uspExpandedCollection}>
		<Heading headingLevel="h2" className={css.uspExpandedCollectionTitle}>
			{title}
		</Heading>
		<div className={css.upsContainer}>
			{usps.map(({ title, image, subtitle }, key) => (
				<UspExpanded title={title} image={image} subtitle={subtitle} key={key} />
			))}
		</div>
	</div>
);

const memoizedUspExpandedCollection = memo(UspExpandedCollection);
export { memoizedUspExpandedCollection as UspExpandedCollection };
