import { type IncomingMessage } from 'http';

import { buildUrlFromServer, buildServerSearchParams } from './urlUtils';

export const checkHomepageRedirect = (req: IncomingMessage): string | false => {
	const searchParams = buildServerSearchParams(req);
	const paramsToDelete = [
		'search',
		'departurerange',
		'theme',
		'isLastMinute',
		'IsLastMinute',
		'distanceToBeach',
		'geo',
	];
	let deleted = false;
	paramsToDelete.forEach((param) => {
		if (searchParams.has(param)) {
			searchParams.delete(param);
			deleted = true;
		}
	});

	return deleted ? buildUrlFromServer(req, searchParams) : false;
};
