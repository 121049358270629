import { type FC, useCallback } from 'react';

import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { LinkCard } from '@vakantiesnl/components/src/__LEGACY__/atoms/LinkCard';
import { type MicroCopy, type LinkCard as LinkCardProps } from '@vakantiesnl/types';

import css from './BrandedSearchPageCollection.module.css';

type BrandedSearchPageCollectionProps = {
	title: string;
	linkCards: LinkCardProps[];
	clickTracking?: (cardPosition: number, cardTitle: string) => void;
	rowId?: number;
	microCopies: MicroCopy;
};

type RenderLinkProps = LinkCardProps & {
	clickTracking?: (cardPosition: number, cardTitle: string) => void;
	parentKey: number;
	parentTitle: string;
	position: number;
};

const RenderLink: FC<RenderLinkProps> = (props) => {
	const onClick = useCallback((): void => {
		if (props.clickTracking) {
			props.clickTracking(props.position, props.title);
		}
	}, [props]);

	return <LinkCard {...props} onClick={onClick} target="_self" />;
};

export const BrandedSearchPageCollection: FC<BrandedSearchPageCollectionProps> = ({
	rowId,
	title,
	linkCards,
	clickTracking,
}) => (
	<div className={css.container}>
		<div className={css.brandedSearchHeader}>
			<Heading headingLevel="h4">{title}</Heading>
		</div>
		<div className={css.brandedSearchPageCollection}>
			{linkCards.map((props, childKey) => (
				<RenderLink
					{...props}
					clickTracking={clickTracking}
					key={`${rowId}-${childKey}`}
					position={childKey}
					parentKey={rowId as number}
					parentTitle={title}
				/>
			))}
		</div>
	</div>
);
