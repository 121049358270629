import { type FC, type PropsWithChildren } from 'react';

import { ClickAwayListener, Popper, type PopperOwnProps } from '@mui/base';

import { useCustomStyles } from './PopperContainer.style';
import { popperModifiers } from '../../molecules/SelectFormField/SelectFormField';

type Props = PropsWithChildren & {
	onClose: () => void;
	popperRef: PopperOwnProps['anchorEl'];
	className?: string;
};

export const PopperContainer: FC<Props> = ({ className, onClose, popperRef, children }) => {
	const { classes, cx } = useCustomStyles();

	return (
		<ClickAwayListener onClickAway={onClose} touchEvent={false}>
			<Popper
				open
				anchorEl={popperRef}
				modifiers={popperModifiers}
				placement="bottom-start"
				role="listbox"
				disablePortal
				className={cx(className, classes.popper)}
			>
				<div className={classes.listBox}>{children}</div>
			</Popper>
		</ClickAwayListener>
	);
};
