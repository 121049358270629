import { useMemo, useRef, useEffect, useState } from 'react';

import { type Booking, type MicroCopy } from '@vakantiesnl/types';

import { getDayMicroCopy, getNightMicroCopy } from '../../util/dateUtils';
import { getFormattedPrice } from '../../util/mathUtils';

export const useGetDaysText = (days: number | undefined, isMobile: boolean, microCopies: MicroCopy): string | null =>
	useMemo((): string | null => {
		if (!days) return null;

		return days + ' ' + getDayMicroCopy(days, isMobile, microCopies);
	}, [days, isMobile, microCopies]);

export const useGetNightsText = (nights?: number, microCopies?: MicroCopy): string | null =>
	useMemo((): string | null => {
		if (!nights || !microCopies) return null;
		return nights + ' ' + getNightMicroCopy(nights, microCopies);
	}, [nights, microCopies]);

export const useGetFlightLabelText = (stops: number, microCopies: MicroCopy): string =>
	useMemo((): string => {
		if (stops === null || stops === 0) {
			return microCopies['tripdata.direct'];
		} else if (stops === 1) {
			return stops + ' ' + microCopies['tripdata.stopOverSingle'];
		} else {
			return stops + ' ' + microCopies['tripdata.stopOverPlural'];
		}
	}, [stops, microCopies]);

export const usePriceToDecimalString = (price: number): string =>
	useMemo((): string => {
		return getFormattedPrice(price);
	}, [price]);

export type MenuState = {
	departureAirportsSelected: string[] | undefined;
	previousDepartureAirportsSelected: string[] | undefined;
	themesSelected: string[] | undefined;
	previousThemesSelected: string[] | undefined;
};

const checkStateFilter = (
	previous: string[] | undefined,
	current: string[] | undefined,
	loadOnAppend: boolean,
): boolean => {
	if (previous === current) {
		return false;
	}

	if (!current) {
		return false;
	}

	// Removing filters
	if (current.length === 0) {
		return false;
	}

	if (!previous) {
		return true;
	}

	if (loadOnAppend) {
		// Deselecting a filter will not showing the loading state
		return previous.length < current.length;
	}

	// Selecting a filter will not showing the loading state except on the first selecting filter
	return previous.length === 0 && current.length === 1 ? true : previous.length > current.length;
};

export const useFilterMenuState = (loading: boolean, state: MenuState): boolean =>
	useMemo((): boolean => {
		const { departureAirportsSelected, previousDepartureAirportsSelected, themesSelected, previousThemesSelected } =
			state;

		return !loading
			? false
			: checkStateFilter(previousDepartureAirportsSelected, departureAirportsSelected, false) ||
					checkStateFilter(previousThemesSelected, themesSelected, true);
	}, [loading, state]);

export const useGetInsurancesText = (insurances: Booking.Insurance[] | undefined): string =>
	useMemo(
		(): string =>
			(insurances || []).reduce((previousVal, current) => {
				return `${previousVal}${previousVal && '\n'}${
					current.proposalDetails ? current.proposalDetails.proposalCommunications.communications.title : ''
				}`;
			}, ''),
		[insurances],
	);

export const usePrevious = <T>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

/**
 * A hook that provides the current scroll direction of the window.
 *
 * @returns {'up' | 'down' | null} - Returns the direction of the scroll:
 * - 'up': when the user scrolls upwards.
 * - 'down': when the user scrolls downwards.
 * - null: when there's no change or initial value.
 *
 * @example
 * const direction = useScrollDirection();
 * if (direction === 'up') {
 *   // Handle scroll up action
 * } else if (direction === 'down') {
 *   // Handle scroll down action
 * }
 *
 * @remarks
 * This hook sets the scroll direction only if the scroll difference
 * is greater than 10 pixels in either direction to avoid frequent
 * changes on minor scrolls.
 */
export const useScrollDirection = (): 'up' | 'down' | null => {
	const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);

	useEffect(() => {
		let lastScrollY = window.scrollY;

		const updateScrollDirection = (): void => {
			const scrollY = window.scrollY;
			const direction = scrollY > lastScrollY ? 'down' : 'up';
			if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
				setScrollDirection(direction);
			}
			lastScrollY = scrollY > 0 ? scrollY : 0;
		};
		window.addEventListener('scroll', updateScrollDirection);
		return () => {
			window.removeEventListener('scroll', updateScrollDirection);
		};
	}, [scrollDirection]);

	return scrollDirection;
};
