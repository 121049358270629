import type { IVariant } from 'unleash-proxy-client';

export interface FeatureToggle {
	[key: string]: {
		isEnabled: boolean;
		variant: IVariant;
	};
}

export const createFeatureToggleRequest = (origin: string): RequestInfo =>
	new Request(`${origin}/api/features`, {
		method: 'GET',
	});
